@import 'functions';
@import 'variables';
@import 'mixins';

@import 'reset';

@import 'helpers';

@import 'cdk';

@import 'fonts';

:root {
  @each $name, $color in $colors {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        $subname: if($subname == 'default', '', '-' + $subname);

        --color-#{$name}#{$subname}: #{$subcolor};
      }
    } @else {
      --color-#{$name}: #{$color};
    }
  }
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  padding-top: 5px;
  background: color('gray', 'light');
  border-radius: 0 $ui-border-radius $ui-border-radius 0;
}

body::-webkit-scrollbar-thumb {
  background: color('gray', 'dark');
  border-radius: $ui-border-radius;
}

body::-webkit-scrollbar-thumb:hover {
  background: color('primary');
}

::-moz-selection {
  background: color('primary');
  text-shadow: none;
  color: #fff;
}

::selection {
  background: color('primary');
  text-shadow: none;
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  border-width: 0;
}

*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #f7f7f7;
  scroll-padding-top: 70px;
}

html {
  font-size: 85%;

  @include when-screen-sm {
    font-size: 90%;
  }

  @include when-screen-md {
    font-size: 95%;
  }

  @include when-screen-min-lg {
    font-size: 100%;
  }
}

body {
  overflow-x: hidden;
  font-size: font('size');
  font-weight: 400;
  font-family: font('family');
  background: #fff;
  color: color('black');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: disc;
}

.error-message {
  line-height: 1;
  font-weight: 600;
  font-size: font('size', 'small');
  color: color('accent');
}

/* Elements & components
-------------------------------------------------------------------------- */
@import 'buttons';
@import 'typography';
@import 'forms';
@import 'editor';
@import 'chart';
@import 'calendar';
@import 'rating';
@import 'autocomplete';
@import 'menu';
@import "accordion";
@import "wizard";

/* Animations
-------------------------------------------------------------------------- */
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

pre {
  max-width: 600px;
  white-space: pre-wrap;
}

.three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.box-shadow {
  box-shadow: 5px 5px 24px -20px rgba(66, 68, 90, 1);
}

.sticky-left-box {
  position: sticky;
  top: 110px;
}

.sticky-box {
  position: sticky;
  top: 70px;
  white-space: nowrap;

  .sticky-container {
    overflow-x: hidden;
  }

  .arrows {
    position: relative;
    top: -12px;
  }

  button {
    height: 32px;
    width: 32px;
    border: 1px solid color('gray');
    background-color: color('gray', 'light');
    border-radius: 100px;
    color: color('gray', 'dark');
    font-weight: 600;
    font-size: 18px;
  }
}

.sticky-box-flow {
  z-index: 9;
  padding-top: 40px;
  background: linear-gradient(to bottom, color('gray', 'light') 50%, white 50%);
  box-shadow: 0px 30px 30px -30px #1014201A;

  .sticky-wrapper {
    background-color: white !important;
  }
}

.sticky-wrapper {
  border-radius: 10px !important;
  padding-top: 1.5rem;
}

.p-component {
  font-family: Figtree !important;
}
.p-overlaypanel {
  z-index: 2 !important;
}

.green-default {
  color: color('green', 'default');
}


.rex-scroll {
  border-radius: $border-radius;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    padding-top: 5px;
    background: color('gray', 'light');
    border-radius: 0 $ui-border-radius $ui-border-radius 0;
  }

  &::-webkit-scrollbar-thumb {
    background: color('gray', 'dark');
    border-radius: $ui-border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color('primary');
  }
}

@media (max-width: $mobile-breakpoint) {
  html,
  body {
    overflow-x: hidden;
  }
}
