@import 'theming';

.p-tabmenu .p-tabmenu-nav {
  background-color: color('gray', 'light') !important;
  border-radius: 100px;
  padding: 6px;
  border: none !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background-color: white !important;
  color: color('black') !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: color('gray', 'light') !important;
  color: color('gray') !important;
  border-radius: 100px;
  padding: 4px 4px 4px 12px !important;
  border: none !important;
  margin: 0 !important;
  border-top-right-radius: 100px !important;
  border-top-left-radius: 100px !important;
  transition: none !important;
  font-weight: 500 !important;;
}
