@import 'theming';

.rex-wizard {
  $classname: &;
  position: relative;

  &__columns {
    margin: 0 auto;
  }

  &__column {
    min-height: 1px;
    max-width: 100%;
    width: 100%;
  }

  &__column--content {
    background: #fff;
    border-radius: $border-radius;
  }

  &__column--sidebar {
    margin: 0 0 25px 0;
    position: relative;

    #{$classname}-sidebar {
      background: #fff;
      border-radius: $border-radius;
      box-shadow: 0 10px 50px rgba(#1d1d1f, 0.1);
    }

    #{$classname}-navigation {
      padding: 30px;
      position: relative;

      &-item {
        @include has-transition;
        margin: 0 0 20px 0;
        position: relative;
        display: flex;
        align-items: center;
        font-size: font('size', 'medium');
        font-weight: 500;
        color: color('gray', 'dark');
        cursor: pointer;

        $indicator-width-and-height: 30px;

        &__indicator {
          position: relative;
          margin: 0 15px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $indicator-width-and-height;
          height: $indicator-width-and-height;

          &:before,
          &:after {
            @include has-transition;
            content: '';
          }

          &:before {
            width: calc($indicator-width-and-height / 6);
            height: calc($indicator-width-and-height / 6);
            border-radius: 50%;
            background: color('gray', 'dark');
          }

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transform: scale(0);
            transform-origin: center;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: inherit;
        }

        /* State: disabled
        -------------------------------------------------------------------------- */
        &#{$classname}-navigation-item--disabled {
          pointer-events: none;
        }

        /* State: current
        -------------------------------------------------------------------------- */
        &#{$classname}-navigation-item--current {
          font-weight: 600;
          font-size: font('size');
          color: color('black');

          #{$classname}-navigation-item__indicator {
            position: relative;

            &:before {
              width: calc($indicator-width-and-height / 3);
              height: calc($indicator-width-and-height / 3);
              background: color('secondary');
            }

            &:after {
              background: rgba(color('secondary'), 0.1);
              transform: scale(1);
            }
          }
        }

        /* State: done
        -------------------------------------------------------------------------- */
        &#{$classname}-navigation-item--done {
          font-weight: 600;
          font-size: font('size');

          #{$classname}-navigation-item__indicator {
            position: relative;

            &:before {
              content: '\e904';
              font-family: 'icomoon';
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
              font-size: 0.625rem;
              line-height: 1;
              color: color('black');
            }

            &:after {
              border: 1px solid color('primary');
              transform: scale(1);
            }
          }
        }
      }
    }

    #{$classname}-cta {
      padding: 30px;
      border-top: 3px solid color('gray', 'light');
    }
  }

  @include wheen-screen-has-min-width(1240px) {
    &__columns {
      display: flex;
      max-width: 1000px;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__column--content {
      width: 550px;
    }

    &__column--sidebar {
      margin: 0;
      position: sticky;
      top: 95px;
      width: 300px;
    }
  }

  @include wheen-screen-has-min-width(1400px) {
    &__columns {
      max-width: 640px + 375px + 20;
    }

    &__column--content {
      width: 640px;
    }

    &__column--sidebar {
      width: 375px;
    }
  }
}
