/**
 * ----------------------------------------------------------------------------------------
 * Elements: Buttons
 * ----------------------------------------------------------------------------------------
 */

a {
  @include has-transition;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

button {
  @include has-transition;
  text-decoration: none;

}

button {
  padding: 0;
  border: none;
  background: none;

  &[disabled] {
    pointer-events: none;
  }
}

button,
[role='button'] {
  cursor: pointer;
}

/* Links
-------------------------------------------------------------------------- */
a,
.as-link {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: color('primary');
  }
}
