@import "theming";

.p-dropdown {
  background: none !important;
  border: none !important;
}



p-dropdown {
  display: inline-block;

  .p-component {
    font-family: Figtree !important;
  }
}

.p-inputtext {
  font-weight: 500 !important;
  color: color('black') !important;
}

.p-dropdown {
  border: none;
}

.p-dropdown-label {
  font-size: 18px !important;
}

.p-dropdown-trigger-icon {
  font-size: 10px !important;
}

.p-dropdown-trigger {
  width: 1.5rem !important;
}

.p-dropdown-items-wrapper {
  max-height: max-content !important;
}

.p-dropdown-panel {
  margin-top: 10px;
  border-radius: 10px;

  .p-dropdown-items {
    padding: 5px !important;

    .p-dropdown-item {
      font-size: 13px !important;
      line-height: 13px;
      color: color('gray');
      font-weight: 500 !important;
      height: 40px !important;
      padding: 8px 8px 5px 15px !important;
    }

    .p-dropdown-item:hover {
      background: color('gray', 'light') !important;
      color: color('black') !important;;
      border-radius: 8px;

      .as-indicator {
        background: white;
        color: color('black')
      }
    }

    .p-dropdown-item.p-highlight {
      background: color('gray', 'light') !important;;
      color: color('black') !important;;
      border-radius: 8px;

      .as-indicator {
        background: white;
        color: color('black')
      }
    }
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-rating {
  display: flex;
  align-items: center;

  .p-rating-icon {
    color: color('rating', 'empty') !important;
    font-size: font('size', 'default') !important;
  }
}

.text-input {
  width: 73% !important;
}

.p-inputwrapper>.p-component {
  width: 100% !important;
}

.p-overlaypanel {
  border-radius: $border-radius;
  box-shadow: 0 10px 50px rgba(#1d1d1f, 0.1);
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 130%;
}

$ui-select-panel-max-height: 290px !default;

.p-autocomplete {
  width: 100%;
}

.p-autocomplete .p-autocomplete-multiple-container {
  font-family: font("family"), serif;
  color: rgba(color("black"), 0.2);
  font-weight: 500;
  font-size: 0.875rem;
  display: inline-block;
  border: none;
  padding: 0;
  width: 100%;
  background: none;
}


.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  font-weight: 500;
  font-size: 0.875rem;
  color: color("black");
  padding: 5px 10px;
  width: auto;
  max-width: 100%;
  border-radius: 5px;
  background: white;
  border: none;
  margin: 2px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-weight: 600;
  font-size: 1rem;
  color: color("black");

  &::-webkit-input-placeholder {
    color: rgba(color("black"), 0.2);
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  font-weight: 500;
  font-size: 0.6rem;
}

.p-autocomplete-token-icon:before {
  content: "\e90b";
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  box-shadow: none;
  border: none;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border: none;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  font-family: font("family"), serif;
  font-size: font("size", "default");
  font-weight: 500;
  max-height: $ui-select-panel-max-height;
  background: #fff;
  margin: 0.1em 0.5em;
  height: 35px;
  border-radius: $ui-border-radius;
  @include has-transition;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  border-radius: $ui-border-radius;
  background: rgba(color("secondary"), 0.1);
  color: color("secondary");
}

.p-autocomplete-panel {
  border-radius: $border-radius;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    padding-top: 5px;
    background: color('gray', 'light');
    border-radius: 0 $ui-border-radius $ui-border-radius 0;
  }

  &::-webkit-scrollbar-thumb {
    background: color('gray', 'dark');
    border-radius: $ui-border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color('primary');
  }
}

.p-inputtext:enabled:focus {
  border: none transparent !important;
  box-shadow: none !important;
}

.p-inputtext:not(.p-disabled):hover {
  box-shadow: none;
  border: none;
}

.p-inputtext:not(.p-disabled).p-focus {
  box-shadow: none;
  border: none;
}

.p-inputtext {
  font-family: font("family"), serif !important;
  border: none !important;
  background: none !important;
  padding-left: 0 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: color("black");
  padding: 0 !important;

  &::-webkit-input-placeholder {
    color: rgba(color("black"), 0.2) !important;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  }
}
