@import "theming";

.p-rating {
  .p-rating-icon {
    font-size: font('size', '2xlarge') !important;
  }

  .p-rating-icon:focus {
    box-shadow: none !important;
  }

  .p-rating-icon.pi-star-fill {
    color: color('rating') !important;
  }

  .p-rating-icon.pi-half-star {
    color: color('rating') !important;
  }

  .p-rating-icon.pi-half-star:before {
    content: '\e93a' !important;
  }
}
.p-rating:hover .p-rating-icon {
  color: color('rating') !important;
  opacity: 1;
}
.p-rating:hover .p-rating-icon:hover ~ .p-rating-icon{
  color: color('rating', 'empty') !important;
  opacity: 1;
}

.p-rating:hover .p-rating-icon:hover ~ .pi-star-fill{
  color: color('rating') !important;
  opacity: 0.3;
}

.pi-star:before {
  content: '\e936' !important;
}
