@import "variables";
@import "fonts";

.ngx-charts {
  text{
    fill: color('gray', 'dark') !important;
    font-family: font('family') !important;
    font-weight: 500 !important;
    font-size: font('size', 'small') !important;
  }
}

.ngx-charts .gridline-path {
  stroke: var(--color-gray-light) !important;
}

.ngx-charts .tooltip-anchor {
  stroke: color('gray', 'default') !important;
}

.ngx-charts .ngx-charts-circle-series {
  display: block !important;
}

.ngx-charts .grid-panel.odd rect {
  fill: none !important;
}

.chart-tooltip {
  text-align: left;

  .chart-tooltip-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}
