@charset "UTF-8";
/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
@import 'normalize.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600;700;800&display=swap");
fieldset {
  padding: 0;
}

/**
 * ----------------------------------------------------------------------------------------
 * Helpers
 * ----------------------------------------------------------------------------------------
 */
.as-unclickable, .as-disabled, .as-processing {
  user-select: none;
  pointer-events: none;
}

.as-processing {
  opacity: 0.7;
  cursor: wait;
}

.as-disabled {
  opacity: 0.4;
}

.as-disabled-but-hoverable {
  opacity: 0.4;
}

.as-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.as-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.as-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 25px;
  border-radius: 50%;
  background: #f6f5fa;
  font-weight: 600;
  font-size: 9px;
  color: #8F8E91;
}

.as-highlight {
  border-radius: 9999px;
  border: 3px solid #eb9b4b;
  width: 5px;
  height: 5px;
}

.prefix-by-dot {
  position: relative;
}
.prefix-by-dot:before {
  content: "•";
  margin: 0 4px;
}

.append-by-dot {
  position: relative;
}
.append-by-dot:after {
  content: "•";
  margin: 0 2px;
}

.comma:not(:empty) ~ .comma:not(:empty):before {
  content: ", ";
}

.purple {
  color: #6B50E5;
}

.as-filter {
  background-color: #f6f5fa !important;
  border: 1px solid #f6f5fa !important;
  color: #8F8E91 !important;
}

.left-column {
  min-width: 400px;
  max-width: 400px;
}

.right-column {
  width: calc(100% - 420px);
}

.rounded-button {
  border: 1px solid #8F8E91 !important;
  color: #949494 !important;
  border-radius: 999px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/**
 * ----------------------------------------------------------------------------------------
 * CDK
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Fonts
 * ----------------------------------------------------------------------------------------
 */
.font-default-black {
  color: #101420 !important;
}

.warning-box {
  background: rgba(235, 155, 75, 0.1) !important;
  color: #eb9b4b;
  font-size: 0.875rem;
}

:root {
  --color-tertiary: #6B50E5;
  --color-secondary-new: #003D98;
  --color-secondary-new-v2: #2F2365;
  --color-primary-new: #57C6F2;
  --color-primary: #FEE25C;
  --color-secondary: #EEEBFF;
  --color-accent-light: #fff0f0;
  --color-accent: #fc5252;
  --color-info: #3684f0;
  --color-success-light: #eafff8;
  --color-success: #1be09f;
  --color-warning: #f9ac1e;
  --color-black: #101420;
  --color-gray-light: #f6f5fa;
  --color-gray: #8F8E91;
  --color-gray-dark: #949494;
  --color-gray-darker: #6C757D;
  --color-gray-darken: #CECDD1;
  --color-gray-border: #E7E6EB;
  --color-gray-new: #767578;
  --color-green-light: #EBFFF8;
  --color-green: #1ef9b0;
  --color-purple-light: #EEEBFF;
  --color-purple: #6B50E5;
  --color-orange-light: rgba(235, 155, 75, 0.1);
  --color-orange: #eb9b4b;
  --color-red-light: rgba(210, 67, 87, 0.1);
  --color-red: #D24357;
  --color-rating: #FEE25C;
  --color-rating-empty: #E7E6EB;
  --color-white: #ffffff;
  --color-gray-border: #E7E6EB;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  padding-top: 5px;
  background: #f6f5fa;
  border-radius: 0 8px 8px 0;
}

body::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #FEE25C;
}

::-moz-selection {
  background: #FEE25C;
  text-shadow: none;
  color: #fff;
}

::selection {
  background: #FEE25C;
  text-shadow: none;
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  border-width: 0;
}

*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #f7f7f7;
  scroll-padding-top: 70px;
}

html {
  font-size: 85%;
}
@media all and (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 90%;
  }
}
@media all and (min-width: 992px) and (max-width: 1169px) {
  html {
    font-size: 95%;
  }
}
@media all and (min-width: 1170px) {
  html {
    font-size: 100%;
  }
}

body {
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Figtree";
  background: #fff;
  color: #101420;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: disc;
}

.error-message {
  line-height: 1;
  font-weight: 600;
  font-size: 0.81rem;
  color: #fc5252;
}

/* Elements & components
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Elements: Buttons
 * ----------------------------------------------------------------------------------------
 */
a {
  transition: all 0.17s ease-in-out 0s;
  text-decoration: none;
}
a:focus {
  outline: none;
}

button {
  transition: all 0.17s ease-in-out 0s;
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  background: none;
}
button[disabled] {
  pointer-events: none;
}

button,
[role=button] {
  cursor: pointer;
}

/* Links
-------------------------------------------------------------------------- */
a,
.as-link {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}
a:hover,
.as-link:hover {
  color: #FEE25C;
}

/**
 * ----------------------------------------------------------------------------------------
 * Elements: Typography
 * ----------------------------------------------------------------------------------------
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.1;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-weight: 700;
  font-size: 1.75rem;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
}

h4 {
  font-weight: 600;
  font-size: 1.125rem;
}

h5 {
  font-weight: 600;
  font-size: 1rem;
}

p {
  margin: 10px 0;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

b,
strong {
  font-weight: 600;
}

.or {
  display: flex;
  align-items: center;
  justify-content: center;
}
.or:before, .or:after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 2px;
  background: #f6f5fa;
}
.or:before {
  margin: 0 10px 0 0;
}
.or:after {
  margin: 0 0 0 10px;
}

.long-text {
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.3;
}
.long-text p, .long-text ul, .long-text ol {
  margin: 15px 0;
}
.long-text li {
  margin: 10px 0;
}

.short-text {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.3;
}

blockquote {
  border-left: 4px solid #949494;
  padding-left: 16px;
}

.red-lens {
  color: #D24357 !important;
  font-weight: 500 !important;
}

/**
 * ----------------------------------------------------------------------------------------
 * Elements: Forms
 * ----------------------------------------------------------------------------------------
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Fonts
 * ----------------------------------------------------------------------------------------
 */
.font-default-black {
  color: #101420 !important;
}

.warning-box {
  background: rgba(235, 155, 75, 0.1) !important;
  color: #eb9b4b;
  font-size: 0.875rem;
}

.ql-editor {
  min-height: 150px;
  font-family: "Figtree";
  font-size: 0.81rem;
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 400;
  color: #1d1d1f;
  overflow-y: initial !important;
  padding: 0 !important;
  line-height: 1.3 !important;
  overflow-wrap: anywhere !important;
}
.ql-editor p, .ql-editor ul, .ql-editor ol {
  margin: 15px 0;
}
.ql-editor li {
  margin: 10px 0;
}

.ql-tooltip {
  z-index: 1;
}

.ql-editor.ql-blank::before {
  font-family: "Figtree";
  font-size: 1rem;
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 400;
  right: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  line-height: 1.15 !important;
  font-style: normal !important;
  color: rgba(16, 20, 32, 0.2) !important;
}

.ql-editor p {
  padding-bottom: 5px !important;
}

/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Fonts
 * ----------------------------------------------------------------------------------------
 */
.font-default-black {
  color: #101420 !important;
}

.warning-box {
  background: rgba(235, 155, 75, 0.1) !important;
  color: #eb9b4b;
  font-size: 0.875rem;
}

.ngx-charts text {
  fill: #949494 !important;
  font-family: "Figtree" !important;
  font-weight: 500 !important;
  font-size: 0.81rem !important;
}

.ngx-charts .gridline-path {
  stroke: var(--color-gray-light) !important;
}

.ngx-charts .tooltip-anchor {
  stroke: #8F8E91 !important;
}

.ngx-charts .ngx-charts-circle-series {
  display: block !important;
}

.ngx-charts .grid-panel.odd rect {
  fill: none !important;
}

.chart-tooltip {
  text-align: left;
}
.chart-tooltip .chart-tooltip-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tertiary {
  color: #6B50E5;
}

.secondary-new {
  color: #003D98;
}

.primary {
  color: #FEE25C;
}

.a3l-datetime .p-calendar {
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 600;
  color: #101420;
}
.a3l-datetime .p-calendar .p-button:hover {
  background: none;
  color: #101420;
  border: none;
}
.a3l-datetime .p-calendar .p-inputtext {
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 600;
  color: #101420;
  font-size: 1rem;
  line-height: 1.15;
  font-family: "Figtree";
}
.a3l-datetime .p-calendar .p-inputtext::-webkit-input-placeholder {
  color: rgba(16, 20, 32, 0.2);
}
.a3l-datetime .p-calendar .p-inputtext:focus {
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-button-icon-only .p-button-label {
  display: none;
}
.a3l-datetime .p-calendar .p-datepicker-trigger {
  padding: 0 0 0 10px;
  border: none;
  border-radius: 0;
  color: #101420;
  background: transparent;
}
.a3l-datetime .p-calendar .p-datepicker-trigger:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-disabled {
  display: none;
}
.a3l-datetime .p-calendar .p-datepicker {
  border-radius: 8px;
  box-shadow: 0 10px 50px rgba(29, 29, 31, 0.1);
  font-family: "Figtree";
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header {
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #101420;
  font-weight: 600;
  margin-right: 0;
  padding: 0.2rem;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:hover {
  color: #949494;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  color: #101420;
  font-weight: 600;
  padding: 0.2rem;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:hover {
  color: #949494;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-next,
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev {
  color: #949494;
  border: none;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-next:hover,
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev:hover {
  color: #101420;
  border: none;
  background: #ffffff;
}
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.a3l-datetime .p-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker table th {
  font-size: 0.75rem;
  color: #949494;
}
.a3l-datetime .p-calendar .p-datepicker table td {
  color: #101420;
  font-weight: 600;
}
.a3l-datetime .p-calendar .p-datepicker table td:focus {
  box-shadow: none !important;
}
.a3l-datetime .p-calendar .p-datepicker table td span:hover {
  color: #ffffff;
  background: #EEEBFF;
}
.a3l-datetime .p-calendar .p-datepicker table td span:focus {
  box-shadow: none !important;
}
.a3l-datetime .p-calendar .p-datepicker table td .p-highlight {
  color: #101420;
  background: #FEE25C;
}
.a3l-datetime .p-calendar .p-datepicker table td .p-highlight:hover {
  color: #ffffff;
  background: #EEEBFF;
}
.a3l-datetime .p-calendar .p-datepicker table td .p-highlight:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker table td:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker {
  color: #101420;
  font-weight: 600;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker .p-highlight, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker .p-highlight {
  color: #101420;
  background: #FEE25C;
  border-radius: 30px;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker .p-highlight:hover, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker .p-highlight:hover {
  color: #ffffff;
  background: #EEEBFF;
  border-radius: 30px;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker .p-highlight:focus, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker .p-highlight:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker span:focus, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker span:focus {
  box-shadow: none !important;
}
.a3l-datetime .p-calendar .p-datepicker .p-yearpicker:focus, .a3l-datetime .p-calendar .p-datepicker .p-monthpicker:focus {
  box-shadow: none !important;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover:hover {
  color: #ffffff;
  background: #EEEBFF;
  border-radius: 30px;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-yearpicker:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover:hover {
  color: #ffffff;
  background: #EEEBFF;
  border-radius: 30px;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) .p-monthpicker:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background-color: #EEEBFF;
}
.a3l-datetime .p-calendar .p-datepicker table td.p-datepicker-today > span {
  color: #949494;
  background: #f6f5fa;
}
.a3l-datetime .p-calendar .p-datepicker table td.p-datepicker-today > span:hover {
  color: #ffffff;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker span, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker span {
  color: #101420;
  font-weight: 600;
  font-size: 1rem;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker .p-ripple, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker .p-ripple {
  color: #949494;
  border: none;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker .p-ripple:hover, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker .p-ripple:hover {
  color: #101420;
  border: none;
  background: #ffffff;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker .p-ripple:focus, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker .p-ripple:focus {
  box-shadow: none;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker .p-ripple span, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker .p-ripple span {
  color: #949494;
  border: none;
  font-weight: 400;
}
.a3l-datetime .p-calendar .p-timepicker .p-hour-picker .p-ripple span:hover, .a3l-datetime .p-calendar .p-timepicker .p-minute-picker .p-ripple span:hover {
  color: #101420;
}

/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tertiary {
  color: #6B50E5;
}

.secondary-new {
  color: #003D98;
}

.primary {
  color: #FEE25C;
}

.p-rating .p-rating-icon {
  font-size: 1.5rem !important;
}
.p-rating .p-rating-icon:focus {
  box-shadow: none !important;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: #FEE25C !important;
}
.p-rating .p-rating-icon.pi-half-star {
  color: #FEE25C !important;
}
.p-rating .p-rating-icon.pi-half-star:before {
  content: "\e93a" !important;
}

.p-rating:hover .p-rating-icon {
  color: #FEE25C !important;
  opacity: 1;
}

.p-rating:hover .p-rating-icon:hover ~ .p-rating-icon {
  color: #E7E6EB !important;
  opacity: 1;
}

.p-rating:hover .p-rating-icon:hover ~ .pi-star-fill {
  color: #FEE25C !important;
  opacity: 0.3;
}

.pi-star:before {
  content: "\e936" !important;
}

/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tertiary {
  color: #6B50E5;
}

.secondary-new {
  color: #003D98;
}

.primary {
  color: #FEE25C;
}

.p-dropdown {
  background: none !important;
  border: none !important;
}

p-dropdown {
  display: inline-block;
}
p-dropdown .p-component {
  font-family: Figtree !important;
}

.p-inputtext {
  font-weight: 500 !important;
  color: #101420 !important;
}

.p-dropdown {
  border: none;
}

.p-dropdown-label {
  font-size: 18px !important;
}

.p-dropdown-trigger-icon {
  font-size: 10px !important;
}

.p-dropdown-trigger {
  width: 1.5rem !important;
}

.p-dropdown-items-wrapper {
  max-height: max-content !important;
}

.p-dropdown-panel {
  margin-top: 10px;
  border-radius: 10px;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 5px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 13px !important;
  line-height: 13px;
  color: #8F8E91;
  font-weight: 500 !important;
  height: 40px !important;
  padding: 8px 8px 5px 15px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  background: #f6f5fa !important;
  color: #101420 !important;
  border-radius: 8px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover .as-indicator {
  background: white;
  color: #101420;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #f6f5fa !important;
  color: #101420 !important;
  border-radius: 8px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight .as-indicator {
  background: white;
  color: #101420;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-rating {
  display: flex;
  align-items: center;
}
.p-rating .p-rating-icon {
  color: #E7E6EB !important;
  font-size: 1rem !important;
}

.text-input {
  width: 73% !important;
}

.p-inputwrapper > .p-component {
  width: 100% !important;
}

.p-overlaypanel {
  border-radius: 8px;
  box-shadow: 0 10px 50px rgba(29, 29, 31, 0.1);
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 130%;
}

.p-autocomplete {
  width: 100%;
}

.p-autocomplete .p-autocomplete-multiple-container {
  font-family: "Figtree", serif;
  color: rgba(16, 20, 32, 0.2);
  font-weight: 500;
  font-size: 0.875rem;
  display: inline-block;
  border: none;
  padding: 0;
  width: 100%;
  background: none;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  font-weight: 500;
  font-size: 0.875rem;
  color: #101420;
  padding: 5px 10px;
  width: auto;
  max-width: 100%;
  border-radius: 5px;
  background: white;
  border: none;
  margin: 2px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-weight: 600;
  font-size: 1rem;
  color: #101420;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input::-webkit-input-placeholder {
  color: rgba(16, 20, 32, 0.2);
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  font-weight: 500;
  font-size: 0.6rem;
}

.p-autocomplete-token-icon:before {
  content: "\e90b";
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  box-shadow: none;
  border: none;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border: none;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  font-family: "Figtree", serif;
  font-size: 1rem;
  font-weight: 500;
  max-height: 290px;
  background: #fff;
  margin: 0.1em 0.5em;
  height: 35px;
  border-radius: 8px;
  transition: all 0.17s ease-in-out 0s;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  border-radius: 8px;
  background: rgba(238, 235, 255, 0.1);
  color: #EEEBFF;
}

.p-autocomplete-panel {
  border-radius: 8px;
}
.p-autocomplete-panel::-webkit-scrollbar {
  width: 8px;
}
.p-autocomplete-panel::-webkit-scrollbar-track {
  padding-top: 5px;
  background: #f6f5fa;
  border-radius: 0 8px 8px 0;
}
.p-autocomplete-panel::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 8px;
}
.p-autocomplete-panel::-webkit-scrollbar-thumb:hover {
  background: #FEE25C;
}

.p-inputtext:enabled:focus {
  border: none transparent !important;
  box-shadow: none !important;
}

.p-inputtext:not(.p-disabled):hover {
  box-shadow: none;
  border: none;
}

.p-inputtext:not(.p-disabled).p-focus {
  box-shadow: none;
  border: none;
}

.p-inputtext {
  font-family: "Figtree", serif !important;
  border: none !important;
  background: none !important;
  padding-left: 0 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: #101420;
  padding: 0 !important;
}
.p-inputtext::-webkit-input-placeholder {
  color: rgba(16, 20, 32, 0.2) !important;
  font-weight: 600 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tertiary {
  color: #6B50E5;
}

.secondary-new {
  color: #003D98;
}

.primary {
  color: #FEE25C;
}

.p-tabmenu .p-tabmenu-nav {
  background-color: #f6f5fa !important;
  border-radius: 100px;
  padding: 6px;
  border: none !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background-color: white !important;
  color: #101420 !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: #f6f5fa !important;
  color: #8F8E91 !important;
  border-radius: 100px;
  padding: 4px 4px 4px 12px !important;
  border: none !important;
  margin: 0 !important;
  border-top-right-radius: 100px !important;
  border-top-left-radius: 100px !important;
  transition: none !important;
  font-weight: 500 !important;
}

.p-accordion-header-text {
  line-height: 1.4 !important;
  margin-right: 20px;
}

.p-accordion-content {
  line-height: 1.4 !important;
}

.p-accordion-header-link {
  border: none !important;
  border-bottom: 2px solid #f6f5fa !important;
  background: white !important;
}

.p-accordion-header-link {
  font-weight: 500 !important;
  color: #101420 !important;
  border-radius: 0 !important;
  padding: 1.25rem 0 !important;
}

.p-accordion-header-link:hover {
  color: #6B50E5 !important;
  border-bottom: 2px solid #6B50E5 !important;
}

.p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-accordion-header-link[aria-expanded=true] {
  color: #6B50E5 !important;
  border-bottom: 2px solid #6B50E5 !important;
}

.p-accordion-content {
  color: #949494 !important;
  border: none !important;
  border-top: 0 !important;
  border-bottom: 2px solid #f6f5fa !important;
  padding: 1.25rem 0 !important;
}
.p-accordion-content p {
  margin: 0 !important;
  font-size: 0.95rem;
}
.p-accordion-content button {
  margin-top: 1.25rem !important;
  color: #101420;
}

.p-accordion-toggle-icon {
  margin: 0 !important;
  display: none !important;
}

/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */
/* Colors
-------------------------------------------------------------------------- */
/* Typography
-------------------------------------------------------------------------- */
/* Layout
-------------------------------------------------------------------------- */
/* UI
-------------------------------------------------------------------------- */
/* RWD
-------------------------------------------------------------------------- */
/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */
/* Screen: XXS
-------------------------------------------------------------------------- */
/* Screen: XS
-------------------------------------------------------------------------- */
/* Screen: SM
-------------------------------------------------------------------------- */
/* Screen: MD
-------------------------------------------------------------------------- */
/* Screen: LG
-------------------------------------------------------------------------- */
/* Screen: XL
-------------------------------------------------------------------------- */
/* Screen: 2XL
-------------------------------------------------------------------------- */
.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tertiary {
  color: #6B50E5;
}

.secondary-new {
  color: #003D98;
}

.primary {
  color: #FEE25C;
}

.rex-wizard {
  position: relative;
}
.rex-wizard__columns {
  margin: 0 auto;
}
.rex-wizard__column {
  min-height: 1px;
  max-width: 100%;
  width: 100%;
}
.rex-wizard__column--content {
  background: #fff;
  border-radius: 8px;
}
.rex-wizard__column--sidebar {
  margin: 0 0 25px 0;
  position: relative;
}
.rex-wizard__column--sidebar .rex-wizard-sidebar {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 50px rgba(29, 29, 31, 0.1);
}
.rex-wizard__column--sidebar .rex-wizard-navigation {
  padding: 30px;
  position: relative;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item {
  transition: all 0.17s ease-in-out 0s;
  margin: 0 0 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: #949494;
  cursor: pointer;
  /* State: disabled
  -------------------------------------------------------------------------- */
  /* State: current
  -------------------------------------------------------------------------- */
  /* State: done
  -------------------------------------------------------------------------- */
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item__indicator {
  position: relative;
  margin: 0 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item__indicator:before, .rex-wizard__column--sidebar .rex-wizard-navigation-item__indicator:after {
  transition: all 0.17s ease-in-out 0s;
  content: "";
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item__indicator:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #949494;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item__indicator:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item:last-child {
  margin-bottom: 0;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item:hover {
  color: inherit;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--disabled {
  pointer-events: none;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--current {
  font-weight: 600;
  font-size: 1rem;
  color: #101420;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--current .rex-wizard-navigation-item__indicator {
  position: relative;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--current .rex-wizard-navigation-item__indicator:before {
  width: 10px;
  height: 10px;
  background: #EEEBFF;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--current .rex-wizard-navigation-item__indicator:after {
  background: rgba(238, 235, 255, 0.1);
  transform: scale(1);
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--done {
  font-weight: 600;
  font-size: 1rem;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--done .rex-wizard-navigation-item__indicator {
  position: relative;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--done .rex-wizard-navigation-item__indicator:before {
  content: "\e904";
  font-family: "icomoon";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 0.625rem;
  line-height: 1;
  color: #101420;
}
.rex-wizard__column--sidebar .rex-wizard-navigation-item.rex-wizard-navigation-item--done .rex-wizard-navigation-item__indicator:after {
  border: 1px solid #FEE25C;
  transform: scale(1);
}
.rex-wizard__column--sidebar .rex-wizard-cta {
  padding: 30px;
  border-top: 3px solid #f6f5fa;
}
@media all and (min-width: 1240px) {
  .rex-wizard__columns {
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .rex-wizard__column--content {
    width: 550px;
  }
  .rex-wizard__column--sidebar {
    margin: 0;
    position: sticky;
    top: 95px;
    width: 300px;
  }
}
@media all and (min-width: 1400px) {
  .rex-wizard__columns {
    max-width: 1035px;
  }
  .rex-wizard__column--content {
    width: 640px;
  }
  .rex-wizard__column--sidebar {
    width: 375px;
  }
}

/* Animations
-------------------------------------------------------------------------- */
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
pre {
  max-width: 600px;
  white-space: pre-wrap;
}

.three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.box-shadow {
  box-shadow: 5px 5px 24px -20px #42445a;
}

.sticky-left-box {
  position: sticky;
  top: 110px;
}

.sticky-box {
  position: sticky;
  top: 70px;
  white-space: nowrap;
}
.sticky-box .sticky-container {
  overflow-x: hidden;
}
.sticky-box .arrows {
  position: relative;
  top: -12px;
}
.sticky-box button {
  height: 32px;
  width: 32px;
  border: 1px solid #8F8E91;
  background-color: #f6f5fa;
  border-radius: 100px;
  color: #949494;
  font-weight: 600;
  font-size: 18px;
}

.sticky-box-flow {
  z-index: 9;
  padding-top: 40px;
  background: linear-gradient(to bottom, #f6f5fa 50%, white 50%);
  box-shadow: 0px 30px 30px -30px #1014201A;
}
.sticky-box-flow .sticky-wrapper {
  background-color: white !important;
}

.sticky-wrapper {
  border-radius: 10px !important;
  padding-top: 1.5rem;
}

.p-component {
  font-family: Figtree !important;
}

.p-overlaypanel {
  z-index: 2 !important;
}

.green-default {
  color: #1ef9b0;
}

.rex-scroll {
  border-radius: 8px;
}
.rex-scroll::-webkit-scrollbar {
  width: 8px;
}
.rex-scroll::-webkit-scrollbar-track {
  padding-top: 5px;
  background: #f6f5fa;
  border-radius: 0 8px 8px 0;
}
.rex-scroll::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 8px;
}
.rex-scroll::-webkit-scrollbar-thumb:hover {
  background: #FEE25C;
}

@media (max-width: 992px) {
  html,
body {
    overflow-x: hidden;
  }
}