/**
 * ----------------------------------------------------------------------------------------
 * Elements: Typography
 * ----------------------------------------------------------------------------------------
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.1;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-weight: 700;
  font-size: font('size', '3xlarge');
}

h2 {
  font-weight: 600;
  font-size: font('size', '2xlarge');
}

h4 {
  font-weight: 600;
  font-size: font('size', 'large');
}

h5 {
  font-weight: 600;
  font-size: font('size');
}

p {
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

b,
strong {
  font-weight: 600;
}

.or {
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: '';
    display: block;
    flex-grow: 1;
    height: 2px;
    background: color('gray', 'light');
  }

  &:before {
    margin: 0 10px 0 0;
  }

  &:after {
    margin: 0 0 0 10px;
  }
}

.long-text {
  font-size: font('size', 'small');
  font-weight: 400;
  line-height: 1.3;

  p, ul, ol
  {
    margin: 15px 0;
  }

  li {
    margin: 10px 0;
  }
}

.short-text {
  font-size: font('size', '2xmedium');
  font-weight: 400;
  line-height: 1.3;
}

blockquote {
  border-left: 4px solid color('gray', 'dark');
  padding-left: 16px;
}

.red-lens {
  color: color('red') !important;
  font-weight: 500 !important;
}
