@import 'functions';
@import 'variables';
@import 'mixins';

.row {
  margin-left: 0;
  margin-right: 0;
}

.full-container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: $mobile-breakpoint) {
  .full-container {
    margin-right: auto;
    margin-left: auto;
  }
}

.tertiary {
  color: color('tertiary');
}

.secondary-new {
  color: color('secondary-new');
}

.primary {
  color: color('primary');
}

