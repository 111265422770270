/* Colors
-------------------------------------------------------------------------- */
$colors: (
  'tertiary': #6B50E5,
  'secondary-new': #003D98,
  'secondary-new-v2': #2F2365,
  'primary-new': #57C6F2,

  'primary': (
    'default': #FEE25C
  ),
  'secondary': #EEEBFF,
  'accent': (
    'light': #fff0f0,
    'default': #fc5252,
  ),
  'info': #3684f0,
  'success': (
    'light': #eafff8,
    'default': #1be09f,
  ),
  'warning': #f9ac1e,
  'black': #101420,
  'gray': (
    'light': #f6f5fa,
    'default': #8F8E91,
    'dark': #949494,
    'darker': #6C757D,
    'darken': #CECDD1,
    'border': #E7E6EB,
  ),
  'gray-new': #767578,
  'green': (
    'light': #EBFFF8,
    'default': #1ef9b0
  ),
  'purple': (
    'light': #EEEBFF,
    'default': #6B50E5,
  ),
  'orange': (
    'light': rgba(235, 155, 75, 0.1),
    'default': #eb9b4b,
  ),
  'red': (
    'light': rgba(210, 67, 87, 0.1),
    'default': #D24357,
  ),
  'rating': (
    'default': #FEE25C,
    'empty': #E7E6EB
  ),
  'white': #ffffff,
  'gray-border': #E7E6EB,
);

@function color($name, $key: null) {
  @return array-get($name, $key, $colors);
}

/* Typography
-------------------------------------------------------------------------- */
$font: (
  'family': 'Figtree',
  'size': (
    'xxxsmall': 0.5rem,
    'xxsmall': 0.625rem,
    'xsmall': 0.75rem,
    'small': 0.81rem,
    'medium': 0.875rem,
    '2xmedium': 0.9375rem,
    'default': 1rem,
    'large': 1.125rem,
    'xlarge': 1.25rem,
    '2xlarge': 1.5rem,
    '3xlarge': 1.75rem,
  ),
);

@function font($name, $key: null) {
  @return array-get($name, $key, $font);
}

$border-radius: 8px;

$browser-context: 16px;

$fonts-path: '/assets/fonts';

/* Layout
-------------------------------------------------------------------------- */
$grid-width: 1300px !default;
$grid-gutter-width: 30px !default;

/* UI
-------------------------------------------------------------------------- */
$ui-border-radius: $border-radius;

/* RWD
-------------------------------------------------------------------------- */
$screen-xxs: 320px;
$screen-xxs-min: $screen-xxs;

$screen-xs: 500px;
$screen-xs-min: $screen-xs;

$screen-sm: 768px;
$screen-sm-min: $screen-sm;

$screen-md: 992px;
$screen-md-min: $screen-md;

$screen-lg: 1170px;
$screen-lg-min: $screen-lg;

$screen-xl: 1250px;
$screen-xl-min: $screen-xl;

$screen-2xl: 1530px;
$screen-2xl-min: $screen-2xl;

$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-2xl-min - 1);

$mobile-breakpoint: 992px;
