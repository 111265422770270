@import "variables";
@import "fonts";

.ql-editor {
  min-height: 150px;
  font-family: font('family');
  font-size: font('size', 'small');
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 400;
  color: #1d1d1f;
  overflow-y: initial !important;
  padding: 0 !important;
  line-height: 1.3 !important;
  overflow-wrap: anywhere !important;

  p, ul, ol
  {
    margin: 15px 0;
  }

  li {
    margin: 10px 0;
  }
}

.ql-tooltip {
  z-index: 1;
}

.ql-editor.ql-blank::before {
  font-family: font('family');
  font-size: font('size');
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 400;
  right: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  line-height: 1.15 !important;
  font-style: normal !important;
  color: rgba(color('black'), 0.2) !important;
}

.ql-editor p {
  padding-bottom: 5px !important;
}
