/**
 * ----------------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------------
 */

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return ($pixels / $context) * 1em;
}

@function rem($values...) {
  $result: ();

  @each $value in $values {
    @if type-of($value) == 'list' {
      $result: rem($value...);
    } @else {
      @if (unitless($value)) {
        $value: $value * 1px;
      }

      $result: append($result, (calc($value / $browser-context)) * 1rem, 'space');
    }
  }

  @return $result;
}

@function map-fetch($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function array-get($name, $key: null, $map: $colors) {
  $value: map-get($map, $name);

  @if (type-of($value) == map) {
    $value: if($key, map-fetch($map, $name, $key), map-fetch($map, $name, 'default'));
  }

  @return $value;
}
