@import "variables";

/**
 * ----------------------------------------------------------------------------------------
 * Fonts
 * ----------------------------------------------------------------------------------------
 */

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600;700;800&display=swap');

.font-default-black {
  color: color('black') !important;
}

.warning-box {
  background: color('orange', 'light') !important;
  color: color('orange');
  font-size: font('size', 'medium');
}
