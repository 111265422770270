@import "theming";

.a3l-datetime .p-calendar {
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  font-weight: 600;
  color: color("black");

  .p-button {
    &:hover {
      background: none;
      color: color("black");
      border: none;
    }
  }
  .p-inputtext {
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    font-weight: 600;
    color: color("black");
    font-size: font("size", "default");
    line-height: 1.15;
    font-family: font("family");

    &::-webkit-input-placeholder {
      color: rgba(color("black"), 0.2);
    }

    &:focus {
      border-radius: 0;
      border: none;
      box-shadow: none;
    }
  }

  .p-button-icon-only .p-button-label {
    display: none;
  }

  .p-datepicker-trigger {
    padding: 0 0 0 10px;
    border: none;
    border-radius: 0;
    color: color("black");
    background: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  .p-disabled {
    display: none;
  }

  .p-datepicker {
    border-radius: $border-radius;
    box-shadow: 0 10px 50px rgba(#1d1d1f, 0.1);
    font-family: font("family");

    .p-datepicker-header {
      padding: 1rem 0.5rem 1rem 0.5rem;

      .p-datepicker-title {
        .p-datepicker-month {
          color: color("black");
          font-weight: 600;
          margin-right: 0;
          padding: 0.2rem;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            color: color("gray", "dark");
          }
        }

        .p-datepicker-year {
          color: color("black");
          font-weight: 600;
          padding: 0.2rem;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            color: color("gray", "dark");
          }
        }
      }

      .p-datepicker-next,
      .p-datepicker-prev {
        color: color("gray", "dark");
        border: none;

        &:hover {
          color: color("black");
          border: none;
          background: color("white");
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    table {
      th {
        font-size: font("size", "xsmall");
        color: color("gray", "dark");
      }

      td {
        color: color("black");
        font-weight: 600;

        &:focus {
          box-shadow: none !important;
        }

        span {
          &:hover {
            color: color("white");
            background: color("secondary");
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .p-highlight {
          color: color("black");
          background: color("primary");

          &:hover {
            color: color("white");
            background: color("secondary");
          }

          &:focus {
            box-shadow: none;
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-yearpicker, .p-monthpicker {
      color: color("black");
      font-weight: 600;

      .p-highlight {
        color: color("black");
        background: color("primary");
        border-radius: 30px;

        &:hover {
          color: color("white");
          background: color("secondary");
          border-radius: 30px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      span {
        &:focus {
          box-shadow: none !important;
        }
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .p-datepicker:not(.p-disabled) {
    .p-yearpicker {
      .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
        &:hover {
          color: color("white");
          background: color("secondary");
          border-radius: 30px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    .p-monthpicker {
      .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
        &:hover {
          color: color("white");
          background: color("secondary");
          border-radius: 30px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    table {
      td {
        span:not(.p-highlight):not(.p-disabled):hover {
          background-color: color("secondary");
        }
      }
    }
  }

  .p-datepicker table td.p-datepicker-today > span {
    color: color("gray", "dark");
    background: color("gray", "light");

    &:hover {
      color: color("white");
    }
  }

  .p-timepicker {
    .p-hour-picker, .p-minute-picker {
      span {
        color: color("black");
        font-weight: 600;
        font-size: font("size", "default");
      }

      .p-ripple {
        color: color("gray", "dark");
        border: none;

        &:hover {
          color: color("black");
          border: none;
          background: color("white");
        }

        &:focus {
          box-shadow: none;
        }

        span {
          color: color("gray", "dark");
          border: none;
          font-weight: 400;

          &:hover {
            color: color("black");
          }
        }
      }
    }
  }
}
