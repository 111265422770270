
.p-accordion-header-text {
  line-height: 1.4 !important;
  margin-right: 20px;
}
.p-accordion-content {
  line-height: 1.4 !important;
}

.p-accordion-header-link {
  border: none !important;
  border-bottom: 2px solid color('gray', 'light') !important;
  background: white !important;
}
.p-accordion-header-link {
  font-weight: 500 !important;
  color: color('black') !important;
  border-radius: 0 !important;
  padding: 1.25rem 0 !important;
}
.p-accordion-header-link:hover {
  color: color('purple') !important;
  border-bottom: 2px solid color('purple') !important;
}
.p-accordion-header-link:focus {
  box-shadow: none !important;
}
.p-accordion-header-link[aria-expanded="true"] {
  color: color('purple') !important;
  border-bottom: 2px solid color('purple') !important;
}

.p-accordion-content {
  color: color('gray', 'dark') !important;
  border: none !important;
  border-top: 0 !important;
  border-bottom: 2px solid color('gray', 'light') !important;
  padding: 1.25rem 0 !important;

  p {
    margin: 0 !important;
    font-size: 0.95rem;
  }

  button {
    margin-top: 1.25rem !important;
    color: color('black')
  }
}

.p-accordion-toggle-icon {
  margin: 0 !important;
  display: none !important;
}
