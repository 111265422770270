/**
 * ----------------------------------------------------------------------------------------
 * Mixins
 * ----------------------------------------------------------------------------------------
 */

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin has-transition($property: all, $duration: 0.17s, $timing: ease-in-out, $delay: 0s) {
  transition: $property $duration $timing $delay;
}

@mixin as-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin as-unclickable {
  pointer-events: none;
  user-select: none;
}

@mixin clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin input-placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin is-safari {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    ::i-block-chrome,
    & {
      @content;
    }
  }
}

@mixin nojs {
  .no-js & {
    @content;
  }
}

@mixin wheen-screen-has-min-width($width) {
  @media all and (min-width: #{$width}) {
    @content;
  }
}

@mixin wheen-screen-has-max-width($width) {
  @media all and (max-width: #{$width}) {
    @content;
  }
}

@mixin wheen-screen-has-min-height($height) {
  @media all and (min-height: #{$height}) {
    @content;
  }
}

@mixin wheen-screen-has-max-height($height) {
  @media all and (max-height: #{$height}) {
    @content;
  }
}

/* Screen: XXS
-------------------------------------------------------------------------- */

@mixin when-screen-xxs {
  @media all and (max-width: ($screen-xs - 1)) {
    @content;
  }
}

/* Screen: XS
-------------------------------------------------------------------------- */

@mixin when-screen-xs {
  @media all and (min-width: $screen-xs) and (max-width: ($screen-sm - 1)) {
    @content;
  }
}

@mixin when-screen-min-xs {
  @media all and (min-width: $screen-xs) {
    @content;
  }
}

@mixin when-screen-max-xs {
  @media all and (max-width: ($screen-sm - 1)) {
    @content;
  }
}

/* Screen: SM
-------------------------------------------------------------------------- */

@mixin when-screen-sm {
  @media all and (min-width: $screen-sm) and (max-width: ($screen-md - 1)) {
    @content;
  }
}

@mixin when-screen-min-sm {
  @media all and (min-width: $screen-sm) {
    @content;
  }
}

@mixin when-screen-max-sm {
  @media all and (max-width: ($screen-md - 1)) {
    @content;
  }
}

/* Screen: MD
-------------------------------------------------------------------------- */

@mixin when-screen-md {
  @media all and (min-width: $screen-md) and (max-width: ($screen-lg - 1)) {
    @content;
  }
}

@mixin when-screen-min-md {
  @media all and (min-width: $screen-md) {
    @content;
  }
}

@mixin when-screen-max-md {
  @media all and (max-width: ($screen-lg - 1)) {
    @content;
  }
}

/* Screen: LG
-------------------------------------------------------------------------- */

@mixin when-screen-lg {
  @media all and (min-width: $screen-lg) and (max-width: ($screen-xl - 1)) {
    @content;
  }
}

@mixin when-screen-min-lg {
  @media all and (min-width: $screen-lg) {
    @content;
  }
}

@mixin when-screen-max-lg {
  @media all and (max-width: ($screen-xl - 1)) {
    @content;
  }
}

/* Screen: XL
-------------------------------------------------------------------------- */

@mixin when-screen-xl {
  @media all and (min-width: $screen-xl) and (max-width: ($screen-2xl - 1)) {
    @content;
  }
}

@mixin when-screen-min-xl {
  @media all and (min-width: $screen-xl) {
    @content;
  }
}

@mixin when-screen-max-xl {
  @media all and (max-width: ($screen-2xl - 1)) {
    @content;
  }
}

/* Screen: 2XL
-------------------------------------------------------------------------- */
@mixin when-screen-2xl {
  @media all and (min-width: $screen-2xl) {
    @content;
  }
}
