/**
 * ----------------------------------------------------------------------------------------
 * Helpers
 * ----------------------------------------------------------------------------------------
 */

.as-unclickable {
  user-select: none;
  pointer-events: none;
}

.as-processing {
  @extend .as-unclickable;
  opacity: 0.7;
  cursor: wait;
}

.as-disabled {
  @extend .as-unclickable;
  opacity: 0.4;
}

.as-disabled-but-hoverable {
  opacity: 0.4;
}

.as-visuallyhidden {
  @include as-visuallyhidden;
}

.as-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.as-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 25px;
  border-radius: 50%;
  background: color('gray', 'light');
  font-weight: 600;
  font-size: 9px;
  color: color('gray');
}

.as-highlight {
  border-radius: 9999px;
  border: 3px solid color('orange');
  width: 5px;
  height: 5px;
}

.prefix-by-dot {
  position: relative;

  &:before {
    content: '\2022';
    margin: 0 4px;
  }
}

.append-by-dot {
  position: relative;

  &:after {
    content: '\2022';
    margin: 0 2px;
  }
}

.comma:not(:empty) ~ .comma:not(:empty):before {
  content: ", ";
}

.purple {
  color: color('purple')
}

.as-filter {
  background-color: color('gray', 'light') !important;
  border: 1px solid color('gray', 'light') !important;
  color: color('gray') !important;
}

.left-column {
  min-width: 400px;
  max-width: 400px;
}

.right-column {
  width:calc(100% - 420px);
}

.rounded-button {
  border: 1px solid color('gray') !important;
  color: color('gray', 'dark') !important;
  border-radius: 999px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
